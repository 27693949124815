.modal-80w {
  width: 95% !important;
  max-width: none !important;
  z-index: 200;
}

.popup_edit_action_modal {
  z-index: 4000;
}

.modal-75w {
  width: 75% !important;
  max-width: none !important;
}

.modal-33w {
  width: 33%;
  max-width: none !important;
}

/* The ImageModal in Conversations */
.conversation-modal {
  display: none;
  position: fixed;
  z-index: 10000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.conversation-modal-content {
  margin: auto;
  display: block;
  max-width: 70vw;
}

.conversation-modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@media only screen and (max-width: 700px) {
  .conversation-modal-content {
    width: 100%;
  }
}

.modal_over_popup {
  background: rgba(0, 0, 0, 0.5);
}

#hide_list_items {
  word-break: break-word;
}
