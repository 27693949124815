.MuiFormLabel-asterisk {
  color: red;
}

.fs-12px {
  font-size: 12px;
  color: $grey;
}

#cancel_job.btn-danger:hover {
  color: white;
}

.exeuction-heading {
  color: $close-to-white;
  background-color: $alternate;
}

.execution-subtitle {
  color: $grey;
  font-size: 14px;
}

.avatar-parameter {
  width: auto !important;
  color: white !important;
}
