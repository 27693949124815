.interactive_accordion {
  padding: 20px 0;
}

.interactive_transfer_source_graph {
  font-weight: bold;
  margin-top: 20px;
}
.interactive_transfer_source {
  font-weight: normal;
  color: $grey;
  font-size: 14px;
}

.interactive_transfer_source_button .btn-link {
  text-decoration: none;
}

.interactive_transfer_source_display {
  margin-top: 30px;
}

.interactive_item_state_created {
  color: $success;
}

.interactive_item_state_deleted {
  color: $red;
}

.interactive_item_state_modified {
  color: $warning;
}

.phil_action_upload_div_inner {
  color: $logo;
}

.phil_action_upload_div,
.interactive_phil_file_td {
  background-color: $close-to-white !important;
}

.change_phil_file_status {
  width: 75%;
}

.phil_remove_file {
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
}

.interactive_chip {
  margin-bottom: 6px !important;
  margin-left: 5px;
}

.ineractive_info_badge {
  margin-left: 5px;
}

.inline_select {
  width: 75%;
}

.details_cell {
  line-height: 15px;
}

.center_span_table {
  margin: 20px 0 !important;
}

.interactive_direction_display {
  margin-top: 11px;
}

.interactive-file-summary-table {
  height: 400px !important;
}

.folder_select_checkbox {
  padding: 5px !important;
}
